// Customizable Area Start
import React from "react";

import JobListingController, {
  configJSON,
  JobListDataType
} from "./JobListingController";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/core/styles';
import { boxLawyer, people3, right ,services,remove} from "./assets";
import StarRatings from 'react-star-ratings';
import VpnLockIcon from '@material-ui/icons/VpnLock';

class JobListing extends JobListingController {
  [x: string]: any;

   getDisabledBackgroundColor = (isDisabled: boolean) => {
    return isDisabled ? "#F0F0F0" : "#355EE7"
  };
  
   getDisabledBackgroundColor2 = (isDisabled: boolean) => {
    return isDisabled ? "grey" : "white"
  };

  getBackgroundColor(selectedOption: string, isDisabled: boolean) {
    if (selectedOption === "NO LEGAL CASE") 
      {
      return "#355EE7"
    }
    return this.getDisabledBackgroundColor(isDisabled)
  }

  getBackgroundColor2(selectedOption: string, isDisabled: boolean) {
    if (selectedOption === "NO LEGAL CASE") 
      {
      return "white"
    }
    return this.getDisabledBackgroundColor2(isDisabled)
  }

  handleAcceptOffer=()=>{
    this.acceptOffer()
    this.props.handleClose()
  }
  handleRejectOffer=()=>{
    if (this.state.selectedOption === '') {
      this.setState({ hasError: true });
    } else {
      this.rejectOffer();
      this.handleRejected();
      this.setState({ hasError: false ,rejectedOffer:true});
    }
   
  }

  getStyles() {
    const isDisabled = this.state.selectedOption === '' || this.state.formData2.rejection_description == ''
    const backgroundColor = this.getBackgroundColor(this.state.selectedOption, isDisabled)
    const textColor = this.getBackgroundColor2(this.state.selectedOption, isDisabled)
    const labelColor = this.state.hasError ? "red" : "black"
    const borderColor = this.state.hasError ? '1px solid red' : '1px solid #C3D1FF'

    return { backgroundColor, textColor, labelColor, borderColor, isDisabled }
  }
  getResponseMessage = () => {
    const { success, errors } = this.state.responseShow;
    if (success) {
      return success;
    } else if (errors) {
      return errors;
    } else {
      return "Lawyer removed from task"; 
    }
  };

  render() {
    const { backgroundColor, textColor, labelColor, borderColor } = this.getStyles()
    const {acceptDialog,rejectDialog,lawyerDialog} = this.props
    const {selectedLawyers} =this.state
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;
    
    return (
      <>
   

        <Box data-test-id="JobListing" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          zIndex: 1300,
          position: "absolute",
          left: "38%",
          top: "20%",

        }}>
          {acceptDialog && (
            <CustomizeDialog
              data-test-id="customizeDialog" >
              <Box style={{
                border: "1px solid #C3D1FF",
                borderRadius: "8px",
                height: "296px",
                padding: "5px",
                fontFamily: "Dm sans",
                fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}><IconButton
                  onClick={this.props.handleClose}
                  style={{
                    marginLeft: "auto",
                    marginBottom: "8%",
                    width: "24px",
                    height: "24px"
                  }}>
                    <CloseIcon /></IconButton>
                </Typography> <Typography style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "126px"
                }}> <img style={{
                  width: "90px",
                  height: "90px"
                }} src={boxLawyer} alt="" /> </Typography>
                <Box style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Typography data-test-id="accept1" style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    color: "black ",
                    fontFamily: "Dm sans",
                    height: "52px"
                  }}>Are you sure you want to accept this lawyer offer?
                  </Typography>
                </Box>

                <DialogActions style={{
                  width: "98%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Button
                    onClick={this.props.handleClose}
                    data-test-id="cancelBtn2"
                    style={{
                      border: "1px solid #8C8C8C",
                      alignItems: "center",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: 700,
                      fontFamily: "Dm sans",
                      color: "#8C8C8C",
                      textTransform: "none",
                      width: "250px",
                      height: "44px",
                      display: "flex",
                    }}>
                    Cancel</Button><Button
                      data-test-id="acceptBtn-1"
                      onClick={this.handleAcceptOffer}
                      style={{
                        backgroundColor: "#355EE7 ",
                        color: "white",
                        borderRadius: "8px",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "Dm sans",
                        textTransform: "none",
                        width: "250px",
                        height: "44px",
                        display: "flex",
                      }}>
                    Accept </Button></DialogActions> </Box>

            </CustomizeDialog>)}

          {rejectDialog && (
            <CustomizeDialog data-test-id="customize2"  >

              <Box style={{
                marginLeft: "24px", fontFamily: "Dm sans", fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}>
                  <IconButton
                    onClick={this.props.rejectClose}
                    style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography> Rejecting Task</Typography>
                <Typography
                  style={{ fontSize: "16px", fontWeight: 400, color: "#8C8C8C ", fontFamily: "Dm sans", width: "93%" }}>
                  Before rejecting this offer it is necessary to provide a clear reason to the lawyer.</Typography>
              </Box>
              <DialogContent style={{
                marginTop: "11px"
              }} >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: labelColor
                      }}
                    >
                      Rejection Reason
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>

                      <select
                        id="customSelect"
                        className="selectCountry"
                        onChange={this.handleChange2}
                        style={{
                          border: borderColor,
                          borderRadius: '12px',
                          width: '100%',
                          padding: '8px',
                        }}
                      >
                        <option style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: "grey"
                        }} value="" disabled selected>
                          Select an option
                        </option>
                        {this.state.optionsReason && this.state.optionsReason.map(option => (
  <option key={option.id} value={option.value}>
    {option.label}
  </option>
))}
                      </select>
                    </Box>
                  </Box>
                </Box>
                {this.state.selectedOption !== "NO LEGAL CASE" && (
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <Typography style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: labelColor
                      }}>
                        Reason description
                        <span style={{ color: "red" }}>*</span>
                      </Typography >


                    </Box>
                    <textarea
                      onChange={this.handleChange}
                      placeholder="Describe the rejection reason"
                      style={{
                        width: "100%",
                        height: "150px",
                        borderRadius: "12px",
                        border: borderColor,
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Dm sans",
                        marginBottom: "10px",
                        padding: "8px"
                      }}>

                    </textarea>
                  </Box>
                )}
              </DialogContent>
              <DialogActions style={{
                width: "94%"
              }}>
                <Button
                  data-test-id="cancelBtn3"
                  onClick={this.props.rejectClose}
                  style={{
                    border: "1px solid #8C8C8C",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    color: "#8C8C8C",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="reject1"
                 onClick={this.handleRejectOffer}
                  style={{
                    backgroundColor: backgroundColor,
                    borderRadius: "8px",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    textTransform: "none",
                    color: textColor
                  }}
                >
                  Save & Reject
                </Button>
              </DialogActions>

            </CustomizeDialog>
          )}
          {lawyerDialog && (
            <CustomizeDialog data-test-id="customize3"  >

              <Box style={{
                fontFamily: "Dm sans", fontWeight: 500,
                fontSize: "22",
                marginLeft:"21px"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}>
                  <IconButton 
                    onClick={this.props.closeLawyer}
                    style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px",marginRight:"12px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography>Assigned lawyers</Typography>
                <Typography
                  style={{ fontSize: "16px", fontWeight: 400, color: "#8C8C8C ", fontFamily: "Dm sans", width: "93%" }}>
                  Here you can see all lawyers working on this task</Typography>
              </Box>
              <DialogContent style={{
                marginTop: "11px",
                padding: "5px"
              }} >
               
                <Box mt={2} style={{
                   display: 'flex', gap: '10px',
                   justifyContent:"flex-start",
                     marginLeft:"8px",
                 flexWrap:"wrap",
                  maxHeight: '407px',
                  overflowY: 'auto',
                  backgroundColor: " #FAFAFA",
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#355EE7 #F0F0F0',
                  fontFamily: "Dm sans",
                  padding: "6px",
                }} >
  {selectedLawyers?.map((lawyer, index) => (
    <Box
      key={index}
      style={{
        border: '1px solid #C3D1FF',
        borderRadius: '10px',
        width: "275px",
        height: "118px",
        display: 'flex',
        flexDirection: 'column',
        backgroundColor:"#FAFAFA"
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "10px",
          alignItems: "center",
          gap: "8px",
          padding: "6px",
          fontFamily: "Dm sans",
        }}
      >
        <Avatar
          src={lawyer.attributes?.associated_lawyer_details.data.attributes?.profile_image?.url || people3}
          style={{
            marginBottom: "12%",
            width: "32px",
            height: "32px",
          }}
        />
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px",
              fontFamily: "Dm sans",
              width: "100%",
            }}
          >
            <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" ,gap:"3px"}}>
              {lawyer.attributes.associated_lawyer_details.data.attributes?.first_name} {lawyer.attributes.associated_lawyer_details.data.attributes?.last_name}
            </Typography>
            <IconButton data-test-id="iconButton"
             onClick={() => this.handleRemoveDialog(lawyer.attributes.associated_lawyer_details.data.id,lawyer.attributes.associated_lawyer_details.data.attributes?.first_name)} 
              style={{
                marginLeft: "auto", width: "12px", height: "12px", 
              }}
            >
              <CloseIcon
                style={{
                  width: "14px", height: "14px",
                  color: "#F35F5F", border: "1px solid #F35F5F",
                  borderRadius: "14px"
                }}
              />
            </IconButton>
          </Box>

          <Box style={{ display: "flex", justifyContent:"space-between" }}>
            <Typography
              variant="body2"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "2px",
                fontFamily: "Dm sans",
                gap: "3px"
              }}
            >
              <VpnLockIcon
                style={{
                  height: "18px",
                  width: "18px",
                  color: "#355EE7",
                }}
              /> <span
                style={{
                  fontSize: "11px",
                  fontFamily: "Dm sans",
                  fontWeight: 400,
                  whiteSpace: 'nowrap'
                }}
              >
                {lawyer.attributes.associated_lawyer_details.data.attributes?.jurisdiction}
              </span>
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "Dm sans",
                color: "#8C8C8C",
                gap: "3px",
                display: "flex",
                alignItems: 'center',
              }}
            >
              4.2{" "}
              <Typography style={{ display: "flex", whiteSpace: 'nowrap' }}>
                <StarRatings
                  rating={4.403}
                  starSpacing="1px"
                  starDimension="14px"
                  starRatedColor="#F59E0B"
                  starEmptyColor="#8C8C8C"
                />
              </Typography>
            </Typography>
          </Box>

          <Box style={{ display: "flex", gap: "8px" }}>
            <Typography
              style={{
                color: "#8C8C8C",
                borderRadius: "6px",
                padding: "2px 6px",
                fontFamily: "Dm sans",
                marginTop: "3px",
                fontSize: "12px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                gap:"3px"
              }}
            >
              <span>{lawyer.attributes.associated_lawyer_details.data.attributes?.years_of_experience} years of experience</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            display: "flex",
            gap: "8px",
            marginLeft: "6px",
          }}
        >
     {lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type?.slice(0, 1).map((service, index) => (
  <Typography
    key={index}
    style={{
      color: "#355EE7",
      backgroundColor: "#ECF1FF",
      borderRadius: "6px",
      padding: "2px 6px",
      fontFamily: "Dm sans",
      marginTop: "3px",
      marginLeft: "2px",
      width: "auto",
      height: "auto",
      fontSize: "11px",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      gap: "4px",
    }}>
    <img
      src={services}
      style={{
        width: "14px",
        backgroundColor: "none",
        height: "14px",
        marginRight: "4px",
      }}
      alt=""
    />
    <span>{service.legal_service_name}</span>
  </Typography>
))}

{lawyer?.attributes?.associated_lawyer_details.data.attributes?.legel_service_type?.length > 1 && (
  <Typography
    style={{
      color: "#355EE7",
      backgroundColor: "#ECF1FF",
      borderRadius: "6px",
      padding: "2px 6px",
      fontFamily: "Dm sans",
      marginTop: "3px",
      marginLeft: "2px",
      width: "auto",
      height: "auto",
      fontSize: "11px",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      gap: "4px",
    }}> <img
    src={services}
    style={{
      width: "14px",
      backgroundColor: "none",
      height: "14px",
      marginRight: "4px",
    }}
    alt=""
  />
    <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 1}</span>
  </Typography>
)}

        </Box>
      </Box>
    </Box>
  ))}
</Box>



              </DialogContent>
              <DialogActions style={{
                width: "96%"
              }}>
                 {userRole === "lawfirm_admin" && (
          <Button
            onClick={this.handleNav}
            data-test-id="reject1"
            style={{
              backgroundColor: "#355EE7",
              borderRadius: "8px",
              width: "150px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 700,
              fontFamily: "Dm sans",
              textTransform: "none",
              color: "white",
            }}
          >
            Assign Lawyer
          </Button>
        )}
              </DialogActions>

            </CustomizeDialog>
          )}
           {this.state.removeDialog && (
            <CustomizeDialog
              data-test-id="customizeDialog" >
              <Box style={{
                border: "1px solid #FEE2E2",
                borderRadius: "8px",
                height: "296px",
                padding: "5px",
                fontFamily: "Dm sans",
                fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}><IconButton
                  onClick={this.handleCloseRemove}
                  style={{ marginLeft: "auto",
                    height: "24px",
                    marginBottom: "8%",
                    width: "24px"}}>
                    <CloseIcon />
                    </IconButton>  </Typography>
                 <Typography 
                 style={{ display: "flex",
                 height: "126px",
                  justifyContent: "center", }}> 
                  <img style={{
                  width: "90px",
                  height: "90px"}} src={remove}
                   alt="" /> 
                   </Typography>
                <Box style={{
                  display: "flex", alignItems: "center",
                  justifyContent: "center" }}>
                  <Typography data-test-id="accept1" 
                  style={{
                    color: "black ",
                    fontFamily: "Dm sans",
                    height: "52px",
                    fontSize: "18px",
                    fontWeight: 400}}>
                      Are you sure you want to remove {this.state.lawyerName} of this task?
                  </Typography>
                </Box>

                <DialogActions style={{
                  width: "98%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Button
                    onClick={this.handleCloseRemove}
                    data-test-id="cancelBtn2"
                    style={{
                      border: "1px solid #8C8C8C",
                      alignItems: "center",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: 700,
                      fontFamily: "Dm sans",
                      color: "#8C8C8C",
                      textTransform: "none",
                      width: "250px",
                      height: "44px",
                      display: "flex",
                    }}>
                    Cancel</Button><Button
                      data-test-id="acceptBtn-1"
                      onClick={() => this.handleRemoveLawyer(this.state.lawyerId)}
                      style={{
                        backgroundColor: "#F35F5F",
                        color: "white",
                        borderRadius: "8px",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "Dm sans",
                        textTransform: "none",
                        width: "250px",
                        height: "44px",
                        display: "flex",
                      }}>
                    Remove Lawyer </Button></DialogActions> </Box>

            </CustomizeDialog>)}

        </Box>

        <Dialog
          PaperProps={{
            style: {
              width: '59vh',
              height: "auto",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }
          }} style={{}} open={this.state.openPop} onClose={this.handleClosePop}
          aria-labelledby="responsive-dialog-title" >

          <Box
            className=""
            sx={{
              width: "57vh",
              borderRadius: "8px",
              border: "1px solid #D1FAE5",
              height: "215px",
            }}>
            <DialogTitle id="responsive-dialog-title" style={{
              textAlign: 'right',
              padding: "7px"
            }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleClosePop} /></DialogTitle>
            <DialogContent
              style={{
                padding: "0px"
              }}
            >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',
                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img src={right} className="imageBox2" alt="success" />
                  </Box>
                  <Typography style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400
                  }}>
                  {this.getResponseMessage()}
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent></Box>


        </Dialog>
       

      
        

      </>
    );
  }
}

export default JobListing;

const CustomizeDialog = styled(Box)({
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF',
    borderRadius: "12px",
    outline: 'none',
    backgroundColor: '#fff',
    fontWeight: 400,
    fontSize: '16px',
    height: "44px",
    fontFamily: "DM Sans",
    color: "black",
    paddingLeft: "8px",
    backgroundPositionY: 'center',

  },

  display: "flex",
  flexDirection: "column",
  boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
  borderRadius: "12px",
  padding: "10px",
  width: "600px",
  background: "white",

  "& .MuiPaper-rounded ": {
    bordeRadius: "17px",
  },
  "& .mainDiv": {
    background: "#FFF",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    borderRadius: "16px",
    padding: "32px 24px 32px 24px",
    gap: "20px",
    fontFamily: "Dm sans",

  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },
  "& .selectBox": {
    border: "1px solid #C3D1FF",
    position: "relative",
    width: "97%",
    borderRadius: "12px",
    color: "black", height: "44px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    "& .MuiInputBase-input::placeholder": {
      color: "#BFBFBF",
    },
    "& .div-root-1 .MuiInputBase-input": {
      padding: "0px"
    }
  },
  "& .forGap": {
    padding: "4px",
  },
  "& . moneySlider": {
    color: "red",
  },
  "& .fontsizing": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#212121",
  },
  "& .selectedUser": {
  
  },
  "& .selectedUser2": {
    border: '1px solid #C3D1FF',
    borderRadius: '10px',
    display: 'flex',
    gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "100%"
  }
});


// Customizable Area End
